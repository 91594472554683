.quoteCategory {
  margin: 2em 0em 7em;
  position: relative;

  .quoteSection {
    background-color: var(--mainDarkNavy);
    padding: 2em 1em 0em 3em;
    position: relative;
  }

  .premText {
    color: var(--mainDarkNavy);
    padding: 1em 1em 0em 2em;
    font-size: 12px;
    position: absolute;
    top: 100%;
  }
}

@media (max-width: 1300px) {
  .quoteCategory {
    margin: 2em 0em 7em;
  }
}

@media (max-width: 767px) {
  .quoteCategory {
    .premText {
      top: 155%;
      margin-left: -3em;
    }
  }
}
