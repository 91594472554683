.partners {
  margin: 1em 0em 2em;
}

.ourPartners {
  font-weight: bold;
  width: 9em;
}

.miwayLogo {
  width: 56%;
  height: auto;
}

.onePlanLogo {
  width: 56%;
  height: auto;
  margin-top: -.5em;
}

.legalXLogo {
  width: 70%;
  height: auto;
}

@media (max-width: 900px) and (min-width: 768px){
  .ourPartners {
    width: 6em;
  }
}

@media (max-width: 767px) {
  .ourPartners {
    text-align: center;
  }

  .miwayLogo {
    width: 36%;
    height: auto;
    padding: 1em;
  }

  .onePlanLogo {
    width: 36%;
    height: auto;
    margin-top: -.5em;
    padding: 1em;
  }

  .legalXLogo {
    width: 50%;
    height: auto;
    padding: 1em;
  }
}

@media (max-width: 500px) {
  .partners {
    margin-left: -1.5em !important;
  }

  .ourPartners {
    text-align: left;
    margin-left: .6em !important;
  }
}
