.paperlessSection {
  text-align: center;
  margin: 1em 0em;
}

.paperlessTitle {
  padding: 1em 1em .3em;
  text-align: center;
  color: var(--mainDarkNavy);
  font-weight: bold;
}

.paperlessSubtitle {
  color: var(--mainDarkNavy);
  margin: 1em 0em;
}

.paperlessText {
  font-size: 12px;
  margin: 1em 0em;
}
