.contactContainer {
  margin: 4em auto;
}

.detailsBlock {
  background-color: var(--mainDarkNavy);
  // background: transparent linear-gradient(214deg, #E750C4 0%, var(--unnamed-color-9f1799) 31%, #8E1899 45%, #621D9A 74%, #34239B 100%) 0% 0% no-repeat padding-box;
  // background: transparent linear-gradient(214deg, #E750C4 0%, #9F1799 31%, #8E1899 45%, #621D9A 74%, #34239B 100%) 0% 0% no-repeat padding-box;
  // padding: 1em 1em 1em 2.3em !important;
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: -1em;
    height: 24em;
    margin-top: -2.65em;
  }
}
