.flexSection {
  background-color: var(--mainDarkNavy);
  padding: 3em 5em 2em 3em;
  margin: 2em 0em;
}

.flexText {
  color: var(--mainWhite);
  font-weight: bold;
}

.alignButton {
  text-align: right;
}

.flexibleLearnMore {
  background-color: var(--mainMint) !important;
  color: var(--mainDarkNavy) !important;
  padding: .5em 2em !important;
}
