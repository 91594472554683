.shortInsuranceSection {
  margin: 0em auto 5em;
}

.shortInsuranceHeader {
  text-align: center;
  color: var(--mainDarkNavy);
}

.shortInsuranceText {
  text-align: center;
  font-size: 12px;
}

.businessInsurance,
.homeInsurance,
.carInsurance,
.petInsurance,
.legalCover,
.medicalInsurance {
  text-align: left !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
}

.businessInsurance {
  text-align: -webkit-right !important;
}

.petInsurance {
  text-align: -webkit-right !important;
}
