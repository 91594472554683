.creditLifeSection {
  position: relative;
}

.creditLifeHeader {
  text-align: center;
  color: var(--mainDarkNavy);
}

.creditLifeText {
  text-align: center;
  font-size: 13px;
}

.insRectangle {
  position: absolute;
  top: -8%;
  bottom: 0;
  left: 20%;
  right: 20%;
  width: 72%;
  max-height: 65%;
  background-color: var(--mainMint);
}

.insRectangleHeader {
  margin: 0 auto;
  width: 100%;
  padding: 1.5em 1em 1.5em 1em;
  line-height: 1.3;
  text-align: left;
  font-weight: 700;
  color: var(--mainDarkNavy);
}

.cardBottom {
  position: absolute;
  bottom: 5em;
  width: 100%;
  text-align: center;

  .insBtn {
    background-color: var(--mainIrisBlue) !important;
    color: var(--mainDarkGreen) !important;
    height: 2.5em;
  }
}

.creditLifeInsurance {
  background-color: var(--mainDarkNavy);
  margin: 5em 0em 5em;

  .insParagraph {
    padding: 3em 2.5em 2em 2em;

    p {
      line-height: 2;
      color: var(--mainDesertStorm);
    }
  }

  .guardRiskDisclaimer {
    color: var(--mainDesertStorm);
    padding: 0em 1em 0em 2.5em;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .insRectangleHeader {
    font-size: 1.5rem;
    line-height: 1.5em;
  }
}

@media (max-width: 650px) {
  .insRectangle {
    display: contents;
  }

  .cardBottom {
    padding-left: 1.5em;
    bottom: -1em;
    text-align: left;
  }
}
