.footerSection {
  background-color: var(--mainDarkNavy);
  padding: 2em 0em;

  .footerLogo {
    width: 50%;
    height: auto;
    margin-bottom: 2em;
  }

  .footerAddress {
    color: var(--mainWhite);
    font-size: 12px;
  }

  .footerNumEmail {
    font-size: 12px;

    .footerNum {
      color: var(--mainWhite);
    }

    .footerEmail {
      color: var(--mainWhite);
    }
  }

  .footerLinks {
    margin-top: 4em;

    h6 {
      color: var(--mainWhite);
      margin-bottom: 1em;
      font-size: 12px;
    }

    p {
      font-size: 11px;
      color: var(--mainLightGray);
    }

    a:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .footerRightSide {
    margin-top: 6em;

    .socMediaLinks {
      margin-bottom: 1em;

      .footerFB {
        width: 2.5%;
      }

      .footerLI {
        width: 5%;
      }

      .footerTW {
        width: 5%;
      }

      .footerIN {
        width: 5%;
      }
    }

    .footerText {
      color: var(--mainLightGray);
      font-size: 11px;
    }

    .footerLogin {
      color: var(--mainDarkNavy) !important;
      background-color: var(--mainMint) !important;
      height: 2em;
      text-transform: capitalize;
    }

    .footerClaim {
      background-color: var(--mainIceGrey) !important;
      color: var(--mainDarkNavy) !important;
      height: 2em;
      text-transform: capitalize;
    }
  }
}

@media (max-width: 768px) {
  .footerSection {
    .footerLogo {
      width: 100%;
    }
  }
}

@media (max-width: 520px) {
  .footerSection {
    .footerRightSide {
      margin-top: 2em;
    }

    .footerLogo {
      width: 50%;
    }
  }
}
