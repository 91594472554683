@font-face {
  font-family: 'IntroBold';
  src: local('IntroBold'), url('Fontfabric-IntroBold.otf') format('opentype');
  font-weight: bolder;
}

@font-face {
  font-family: 'Intro';
  src: local('Intro'), url('Fontfabric-IntroRegular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Intro';
  src: local('Intro'), url('Fontfabric-IntroSemiBold.otf') format('opentype');
  font-weight: bold;
}
