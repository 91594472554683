.getsureLogo {
  width: 43%;
  height: auto;
}

.loginButton {
  background-color: var(--mainMint) !important;
  color: var(--mainDarkNavy) !important;
  height: 2em;
  text-transform: capitalize;
}

.claimButton {
  color: var(--mainDarkNavy) !important;
  background-color: var(--mainIceGrey) !important;
  height: 2em;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0rem !important;
  }

  .getsureLogo {
    width: 100%;
  }
}
