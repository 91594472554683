.formSection {
  background-color: var(--mainGhostWhiteTwo);
  padding: 2em 2em 2em 3em;
}

.formSectionSubmitted {
  background-color: var(--mainGhostWhiteTwo);
  padding: 2em 2em 2em 3em;
  margin-top: -2.65em;
}

input {
  font-size: 10px !important;
  padding-left: 3em !important;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
  border-bottom-color: var(--mainBlack);
  border-radius: 0px !important;
  color: var(--mainBlack) !important;
  background-color: var(--mainGhostWhiteTwo) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none;
}

.form-control {
  height: calc(3.5em + 0.75rem + 2px) !important;
  color: var(--mainBlack) !important;
}

.form-group {
  margin-bottom: 1rem !important;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.formIconNumber {
  position: absolute;
  top: .5em;
  width: 4%;
  height: auto;
}

.formIconEmail {
  position: absolute;
  top: 1.1em;
  width: 5%;
  height: auto;
}

.formIconName {
  position: absolute;
  top: .8em;
  width: 5%;
  height: auto;
}

.form-label {
  font-size: 10px;
  padding-left: 3em;
}

.textArea {
  font-size: 10px !important;
  padding-left: 3em !important;
  color: var(--mainBlack);
  border-top-style: hidden !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
  border-bottom-style: hidden !important;
  border-radius: 0px !important;
  background-color: var(--mainWhite) !important;
}

.textArea.form-control {
  height: calc(8.5em + 0.75rem + 2px) !important;
}

.formIconMessage {
  position: absolute;
  top: .3em;
  width: 5%;
  height: auto;
}

.form-check-label {
  font-size: 10px;
}

.form-check-input {
  margin-top: .6rem !important;
}

.termsUrl {
  text-decoration: underline;
  color: var(--mainBlack);
}

.termsUrl:hover {
  color: var(--mainBlack);
}

.formButton {
  background-color: var(--mainDarkNavy) !important;
  color: var(--mainWhite) !important;
  height: 2.5em;
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

.submitSuccess {
  background-color: var(--mainDarkNavy);
  text-align: left;
  font-weight: bold;
  margin-top: -.8em;
  margin-bottom: 1em;
  padding: 1em 1em .2em 2em;

  .submitText {
    color: var(--mainWhite);
    font-size: 13px;
  }
}
