.coverBlockSection {
  margin: 2em auto 0;
  overflow: hidden;

  h4 {
    color: var(--mainMint);
  }

  img {
    height: 4em;
    margin-bottom: 1em;
    color: var(--mainWhite);
  }
}

.coverBlock {
  background-color: var(--mainDarkNavy);
  padding: 2em 1em 100% 1em;
  margin-bottom: -95%;

  .coverBlockText {
    color: var(--mainWhite);
    font-size: 13px;
    line-height: 1.7;
    padding-top: .5em;
    margin-bottom: 3em;
  }

  .coverButton {
    text-align: center;

    a {
      .coverLearnMoreBtn {
        display: table-cell;
        vertical-align: bottom;
        background-color: var(--mainMint) !important;
        color: var(--mainDarkNavy) !important;
        padding: .6em 2em !important;
        margin-top: 1em;
        text-decoration: none;
        position: absolute;
        margin-left: -50px;
        left: 50%;
        /* width: 100px; */
        bottom: 19px;
      }
    }
  }
}


@media (max-width: 500px) {
  .coverRow {
    display: block !important;
  }

  .coverBlock {
    padding: 2em .5em 2em .5em;
    margin-bottom: 1em;
  }
}
