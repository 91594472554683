.coverDisplay {
  display: flex;

  p {
    margin-right: .3em;
    color: var(--mainDarkNavy);
  }

  .chooseLabelContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: .5em;
    margin-right: 1em;
    font-weight: bold;
  }

  /* Hide the browser's default radio button */
  .chooseLabelContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 15px;
    width: 15px;
    background-color: var(--mainLinkWater);
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .chooseLabelContainer:hover input ~ .checkmark {
    background-color: var(--mainLinkWater);
  }

  /* When the radio button is checked, add a blue background */
  .chooseLabelContainer input:checked ~ .checkmark {
    background-color: var(--mainLinkWater);
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .chooseLabelContainer input:checked ~ .checkmark:after {
    display: block;
  }

  .chooseLabelContainer .checkmark:after {
   	top: 3px;
  	left: 3px;
  	width: 9px;
  	height: 9px;
  	border-radius: 50%;
  	background: var(--mainMint);
  }
}
