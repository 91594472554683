.funeralText {
  text-align: center;

  h5 {
    color: var(--mainDarkNavy);
  }

  p {
    color: var(--mainTangora);
    font-size: 13px;
    padding: 0em 2em;
  }
}
