//Home
.freeWillBanner {
  text-align: left !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
  height: 21em !important;

  h2:first-child {
    font-size: 1.7rem !important;
    color: var(--mainDarkNavy) !important;
  }

  h2:nth-child(2) {
    font-size: 2.3rem !important;
    color: var(--mainDarkNavy) !important;
  }
}

.freeWillBtn {
  color: var(--mainDarkNavy) !important;
  background-color: var(--mainMint) !important;
  padding-top: .5em !important;
  padding-bottom: .5em !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

// About Page
.aboutBannerStyle {
  text-align: left !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
}

.whyNiftySure {
  background-color: var(--mainWhiteSmoke);
}

// CreditLife
.creditBannerStyle {
  text-align: -webkit-right !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
}

// ComuterLife
.commuterBannerStyle {
  text-align: -webkit-left !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
}

// FAQ
.faqBannerStyle {
  text-align: -webkit-right !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
}

.whyGetSure {
  background-color: var(--mainSolitude);
}

// News
.newsBannerStyle {
  text-align: -webkit-right !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
}

// SingleNews
.singleNewsBannerStyle {
  text-align: -webkit-right !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
}

//SmartWills
.smartWillBannerStyle {
  text-align: -webkit-right !important;
  padding: 0em 4em;
  display: table-cell;
  vertical-align: middle;
}

// Error
.errorStyle {
  text-align: left !important;
  padding: 0em 4em !important;
  display: table-cell !important;
  vertical-align: middle !important;
  margin: 0 auto !important;
}

.errorLink {
  color: var(--mainGetsureGreen);
  text-decoration: none !important;
}

.errorLink:hover {
  color: var(--mainDarkNavy);
}

@media (max-width: 500px) {
  .freeWillBanner {
    height: 21em !important;
    text-align: center !important;
    vertical-align: middle;
    background-repeat: no-repeat;
    width: unset !important;
    color: #ffffff;
    display: grid;
    justify-items: center !important;
    align-items: center !important;;
    padding: 1.5em 2em;
    text-align: -webkit-center !important;

    h2:first-child {
      font-size: 1.4rem !important;
      color: var(--mainDarkNavy) !important;
    }

    h2:nth-child(2) {
      font-size: 1.7rem !important;
      color: var(--mainSelectiveYellow) !important;
    }
  }
}
