.nav-link {
  display: inline !important;
  padding: .5rem 0rem;
  color: var(--mainWhite) !important;
}

.navDropdown {
  font-size: 11px;
  color: var(--mainDarkNavy) !important;
}

.navDropdown:hover {
  background-color: var(--mainTransparent) !important;
}

.verticalLine {
  color: var(--mainSolitude);

}

.navbar-toggler {
  border: 1px solid white !important;
  background-color: white !important;
  margin-bottom: 1em;
}

.dropdown-divider {
  margin: .5rem 1em !important;
}

.dropdown-menu {
  background-color: var(--mainIceGrey) !important;
}

.navbar__link {
  font-size: 14px !important;
  padding: 1em 1em !important;
  color: var(--mainWhite) !important;
}

.navbar__link:hover {
  transform: translateY(-2px);
  text-decoration: none;
}

.navbar__link--active {
  color: var(--mainMint) !important;
}

.contactStyle {
  color: var(--mainWhite) !important;
}

.contactStyle:hover {
  transform: translateY(-2px);
  text-decoration: none;
}

.navbar-brand {
  width: 30%;
}

// .dropdown-menu {
//   border-width: 0.6ex 0.6ex 0px;
//   -moz-appearance:none; /* Firefox */
//   -webkit-appearance:none; /* Safari and Chrome */
//   appearance:none;
// }

.dropdown-menu:before {
  content:"";
  position: absolute;
  left: .2px;
  top: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--mainIceGrey) transparent;
  z-index:9999;
}
.dropdown-menu:after {
  content:"";
  position: absolute;
  left: 4px;
  top: -24px;
  width: 0;
  height: 0;
  border-width: 0 17px 17px 17px;
  border-color: transparent transparent #ffffff transparent;
  z-index:9998;
  border-style: hidden !important;
  border: 0px solid var(--mainDarkNavy) !important;
}
.dropdown-menu {
  background: var(--mainIceGrey);
  border-style: hidden !important;
  border: 0px solid var(--mainIceGrey) !important;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0rem !important;
  left: 22px !important;
  position: absolute !important;
  color: var(--mainDarkNavy)!important;
  margin: 0;
  top: 2.8em;
  width: 200px;
  z-index: 99999;
}
