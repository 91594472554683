.whyGetSureSection {
  .contentSection {
    text-align: center;
    margin-bottom: 3em;

    .contentTitle {
      color: var(--mainDarkNavy);
      font-size: 15px;
      padding: 0em 1em
    }

    .contentText {
      font-size: 12px;
      padding: 0em 1em;
      color: var(--mainBlack);
    }

    .heartImg {
      width: 11%;
      margin-top: 1em;
      margin-bottom: 1em;
      height: auto;
    }

    .controlImg {
      width: 11%;
      margin-top: 1em;
      margin-bottom: 1em;
      height: auto;
    }

    .effortImg {
      width: 11%;
      margin-top: 1em;
      margin-bottom: 1em;
      height: auto;
    }
  }
}
