.commuterSection {
  position: relative;
  margin-bottom: 9em;
}

.commuterHeader {
  text-align: center;
  color: var(--mainDarkNavy);
}

.commuterText {
  text-align: center;
  font-size: 13px;
}

.coverSection {
  background-color: var(--mainDarkNavy);
  position: relative;
  margin: 6em auto 4em;

  .coverRectangle {
    position: absolute;
    top: -5%;
    bottom: 0;
    left: 55%;
    width: 30%;
    max-height: 60%;
    background-color: var(--mainMint);
  }

  .coverImg {
    margin: 0 auto;
    width: 100%;
    display: block;
    padding: 1.4em;
  }

  .benefits {
    color: var(--mainWhite);
    padding-left: 2.2em;
    padding-right: 2em;
    font-size: 16px;
    line-height: 2;
    padding-bottom: 2em;
  }
}

@media (max-width: 1200px) {
  .coverSection {
    .coverImg {
      padding: 1em;
    }
  }
}

@media (max-width: 767px) {
  .coverSection {
    .coverRectangle {
      display: none;
    }

    .benefits {
      padding: .5em 2.5em;
    }
  }
}
