.smartWillSection {
  margin: 0em auto 5em;
}

.smartWillHeader {
  text-align: center;
  color: var(--mainDarkNavy);
}

.smartWillText {
  text-align: center;
  font-size: 12px;
}

.smartWillHeaderTwo {
  color: var(--mainDarkNavy);
  text-align: center;
  font-weight: bold;
  margin-top: 2em;
}

.smartWillTextTwo {
  color: var(--mainDarkNavy);
  text-align: center;
  line-height: 1.8;
  font-size: 13px;
}

.smartWillLetsGo {
  background-color: var(--mainDarkNavy);
  text-align: center;
  padding: 2em 2em 3em;
}

.smartWillLetsGoText {
  font-size: 15px;
  color: var(--mainWhite);
}

.smartBtn {
  background-color: var(--mainMint) !important;
  color: var(--mainDarkNavy) !important;
  height: 2.5em;
  // box-shadow: 2px 1px 2px 1px #0000007D;
}
