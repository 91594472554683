.payoutSection {
  background-color: var(--mainDarkNavy);
  position: relative;
  margin: 9em auto 6em;

  .payoutRectangle {
    position: absolute;
    top: -5%;
    bottom: 0;
    left: 55%;
    width: 30%;
    max-height: 60%;
    background-color: var(--mainMint);
  }

  .payoutImg {
    margin: 0 auto;
    width: 100%;
    display: block;
    padding: 2em;
  }

  .benefits {
    color: var(--mainWhite);
    padding-left: 2.2em;
    padding-right: 2em;
    font-size: 16px;
    line-height: 2;
  }
}

@media (max-width: 1200px) {
  .payoutSection {
    .payoutImg {
      padding: 1em;
    }
  }
}

@media (max-width: 767px) {
  .payoutSection {
    .payoutRectangle {
      display: none;
    }

    .benefits {
      padding: .5em 2.5em;
    }
  }
}
