.costSection {
  background-color: var(--mainDarkNavy);
  margin: 1.5em auto 4em;

  .costBlock {
    padding: 3em 1em 2em 5em;
  }

  .costBlockTwo {
    padding: 1em 1em 1em 5em;
  }

  .costHeader {
    color: var(--mainMint);
    font-size: 16px;
  }

  .costText {
    color: var(--mainDesertStorm);
    font-size: 13px;
  }
}

.sideImages {
  .costEffectiveBox {
    background-color: var(--mainMint);
    position: absolute;
    width: 100px;
    padding: 25px;
    z-index: 10;
    margin: 64px auto 0em 1.5em;

    .costeffectiveImg {
      width: 100%;
      height: auto;
    }
  }

  .accessBox {
    background-color: var(--mainMint);
    position: absolute;
    width: 100px;
    padding: 25px;
    z-index: 10;
    margin: 175px auto 0em 1.5em;

    .accessImg {
      width: 100%;
      height: auto;
    }
  }
}

.costAlignBtn {
  text-align: center;
  margin-bottom: 2em;

  .costGetQuote {
    background-color: var(--mainMint) !important;
    color: var(--mainDarkNavy) !important;
    padding: 0.6em 2em !important;
    margin-top: 1em;
  }

  .costLearnMore {
    background-color: var(--mainIceGrey) !important;
    color: var(--mainDarkNavy) !important;
    padding: 0.6em 2em !important;
    margin-top: 1em;
  }
}

@media (max-width: 768px) {
  .costSection {
    margin-bottom: 2.5em;

    .costBlock {
      padding-left: 2em;
    }

    .costBlockTwo {
      padding-left: 2em;
    }
  }

  .sideImages {
    .costEffectiveBox {
      display: none;
    }

    .accessBox {
      display: none;
    }
  }
}
