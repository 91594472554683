* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --mainTransparent: transparent;
  --mainGetsureGreen: #3EBB9F;
  --mainOxfordBlue: #231f20;
  --mainGhostWhite: #f2f3f5;
  --mainSelectiveYellow: #feb660;
  --mainPalatinatePurple: #832359;
  --mainNiagara: #36AD8E;
  --mainSolitude: #E9EAEE;
  --mainGreen: #0000007C;
  --mainSilver: #BABABA;
  --mainDimGray: #707070;
  --mainYellowOne: #FFFF00;

  --swiper-theme-color: var(--mainDarkNavy) !important;


  --mainDarkMagenta: #9F1799;
  --mainBlack: #000000;
  --mainIrisBlue: #04B5BC;
  --mainWhite: #ffffff;
  --mainBlackPearl: #001B2C;
  --mainTangora: #042342;
  --mainSoltitude: #E9EAEE;
  --mainPelorous: #1B9AAA;
  --mainLightGray: #D3D3D3;
  --mainWhiteSmoke: #F5F5F5;
  --mainDesertStorm: #EDEBE8;
  --mainLinkWater: #CCD2DD;
  --mainNobel: #9B9B9B;
  --mainSnow: #F9F9F9;
  --mainDarkGreen: #052D25;
  --mainPalatinatePurple: #832359;
  --mainTrout: #4A5259;
  --mainGhostWhiteTwo: #F5F5F7;


  --mainMetallicBlue: #03129F;
  --mainMint: #64EBA9;
  --mainWhite: #FFFFFF;
  --mainIceGrey: #DAE1E9;
  --mainDarkNavy: #06061E;
}

body { background: var(--mainWhite); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Archivo', sans-serif;
}

p {
  font-family: 'Open Sans', sans-serif;
}

/* font-family: 'Archivo', sans-serif;
font-family: 'Lobster', cursive;
font-family: 'Open Sans', sans-serif; */
