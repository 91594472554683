.containerFluid {
  width: 100%;
  padding-right: 3.5em;
  padding-left: 0em;
  margin-right: auto;
  margin-left: -1em;
  margin-bottom: 1em;
}

.funeralSection {
  margin-top: 4em;
  margin-bottom: 2em;
}

.funeral {
  background-color: var(--mainGhostWhiteTwo) !important;
}

.quoteTitle {
  color: var(--mainDarkNavy) !important;
}

.amountNeeded {
  color: var(--mainDarkNavy);
}

.focusAmount {
  input {
    border-top-style: hidden !important;
    border-bottom-style: hidden !important;
    background-color: var(--mainSolitude) !important;
    color: var(--mainWhite) !important;
  }

  .form-control {
    color: var(--mainDarkNavy) !important;
    height: calc(1.5em + 0.75rem + 11px) !important;
    margin-bottom: 2em;
  }

  b {
    width: 1.26ex !important;
    height: 1em;
    box-shadow: none !important;
    background-color: var(--mainSolitude) !important;
    padding: 1em 1.5em 0.5em 0.5em;
    border: none !important;

    i {
      position: absolute;
      border-color: var(--mainBlack) transparent !important;
      margin: 0em 0px 0px -0.56ex;
    }
  }
}

.amountBlock {
  background-color: var(--mainDarkNavy);
}

.monthlyPremium,
.monthlyPremiumText {
  color: var(--mainWhite) !important;
}

.mainMember,
.addFamilyMembers {
  color: var(--mainDarkNavy);
}

.rightSideFormBlock {
  input {
    border-bottom: 1px solid var(--mainNobel) !important;
    border-bottom-style: solid !important;
    background-color: var(--mainGhostWhiteTwo) !important;
    color: var(--mainDarkNavy) !important;
  }
}

.rightFuneralCheckbox label:before {
  border: 1px solid var(--mainSilver) !important;
}

.rightFuneralCheckbox label:after {
  border-bottom: 2px solid var(--mainDarkNavy) !important;
  border-left: 2px solid var(--mainDarkNavy) !important;
}

.rightBlockCheckbox {
  color: var(--mainDarkNavy) !important;
  background-color: var(--mainGhostWhiteTwo) !important;
  font-weight: bold !important;

  label {
    color: var(--mainDarkNavy);
    font-weight: bold;
  }
}

.rightBlockFormControl {
  .form-control {
    color: var(--mainDarkNavy);
  }
}

@media (max-width: 500px) {
  .containerFluid {
    padding-right: 0em;
  }

  .funeralSection {
    margin-top: 2em;
  }

  // .monthlyPremium,
  // .monthlyPremiumText {
  //   color: var(--mainIrisBlue) !important;
  // }
}

@media (max-width: 1300px) {
  .monthlyPremium,
  .monthlyPremiumText {
    color: var(--mainIrisBlue) !important;
  }
}

@media screen and (max-width: 1024px) {
  .focusAmount {
    b {
      padding: 1em 3em 0.5em 0.5em;
    }

    i {
      border-color: var(--mainDarkNavy) !important;
      background-color: var(--mainDarkNavy) !important;
    }
  }
}
