.addy {
  padding: 1em;
}

.locationDisplay {
  display: flex;

  .location {
    font-size: 12px;
    margin-left: 1.5em;
    color: var(--mainWhite);
  }

  .locationIcon {
    width: 6%;
    height: auto;
    margin-top: -3.9em;
    // vertical-align: middle;
    // display: inline-block;
    // margin-top: -3.4em;
  }
}

.mobileLink {
  font-size: 12px;
  color: var(--mainWhite);

  .mobileIcon {
    width: 5%;
    height: auto;
    margin-right: 1.5em;
  }
}

.mobileLink:hover {
  text-decoration: none;
  color: var(--mainWhite);
}

.mail {
  margin-top: 0.6em;

  .mailLink {
    font-size: 12px;
    color: var(--mainWhite);

    .mailIcon {
      width: 7%;
      height: auto;
      margin-right: 1.5em;
    }
  }

  .mailLink:hover {
    text-decoration: none;
  }
}

.contactSocMedia {
  color: var(--mainWhite);
  font-size: 12px;

  .contactLI {
    margin-top: -1.1em;
    width: 6%;
  }

  .contactFB {
    margin-top: -1.1em;
    width: 3.5%;
  }

  .contactTW {
    margin-top: -1.1em;
    width: 6%;
  }

  .contactIN {
    margin-top: -1.1em;
    width: 6%;
  }
}
