.getsureIcon {
  width: 45%;
  height: auto;
  margin: 0em 0em 1em;
}

.whySummary {
  text-align: left;
  color: var(--mainDarkNavy);
}

.whyText {
  font-size: 12px;
  margin: 2em 0em 1em;
  color: var(--mainBlack);
}

.whyLearnMore {
  background-color: var(--mainMint) !important;
  color: var(--mainDarkNavy) !important;
  height: 2.5em;
}

@media (max-width: 500px) {
  .getsureIcon {
    width: 20%;
  }
}
