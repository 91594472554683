.faqTabsSection {
  .nav-tabs {
    border-bottom-style: hidden !important;
    border: hidden !important;
    font-size: 13px;
    margin-bottom: 2em !important;
    outline: none !important;
  }

  .nav-tabs .nav-link.active {
    color: var(--mainSolitude) !important;
    padding: .7rem 3rem !important;
    background-color: var(--mainDarkNavy) !important;
    max-width: 13em !important;
    width: 13em;
    text-align: center;
  }

  .nav-tabs .nav-link {
    border-bottom-style: hidden !important;
    border-top-style: hidden !important;
    border-left-style: hidden !important;
    border-right-style: hidden !important;
    border: 0px solid transparent !important;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    color: var(--mainDarkNavy) !important;
    box-shadow: 2px 1px 2px 1px #00000029;
    background-color: var(--mainSolitude);
    padding: .7rem 3rem !important;
    max-width: 13em !important;
    width: 13em;
    text-align: center;
    font-size: 13px !important;
  }

  .nav-tabs .nav-item {
    margin-right: 1em;
    margin-bottom: 1em !important;
    outline: none !important;
  }

  .nav-link {
    padding: .7rem 3rem !important;
  }

  .accordionTitle {
    padding-bottom: .5em;
    padding-top: .5em;
    margin-top: 2em;
    color: var(--mainDarkNavy);
  }

  .accordionTitle:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    /* margin-right: 12px; */
    text-align: right;
    float: right;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
  }

  .accordionTitle[aria-expanded='true']::after,
  .accordionTitle[aria-selected='true']::after {
    transform: rotate(223deg);
  }

  .accordionTitle:focus {
    outline:0;
    outline: none;
  }

  .faqTabsSection {
    margin-bottom: 6em;
  }

  .creditLifeList {
    list-style-type: lower-roman !important;
    padding-left: 1.3em !important;
  }

  .funeralPad {
    .nav-tabs .nav-link {
      padding: .9rem 3.5rem !important;
    }
  }
}
