.shortTermContentSection {
  margin: 7em 3em 4em -1em;
  background-color: var(--mainDarkNavy);
  position: relative;

  .shortTermContentRectangle {
    position: absolute;
    top: -5%;
    bottom: 0;
    left: 55%;
    width: 30%;
    max-height: 60%;
    background-color: var(--mainMint);
  }

  .shortTermImg {
    margin: 0 auto;
    width: 100%;
    display: block;
    padding: 1em 1.6em;
  }

  .benefits {
    color: var(--mainWhite);
    padding-left: 2.2em;
    padding-right: 2em;
    font-size: 14px;
    line-height: 2;
  }

  .shortTermTitle {
    color: var(--mainMint);
    padding-bottom: .5em;
  }

  .shortTermText {
    color: var(--mainWhite);
    font-size: 14px;
  }

  .shortTermSmallText {
    font-size: 9px;
    color: var(--mainWhite);
  }

  .shortTermRight {
    margin: 3em auto 1.5em;
    padding: 1em;
  }

  .shortInsBtn {
    background-color: var(--mainMint) !important;
    color: var(--mainDarkNavy) !important;
    height: 2.5em;
    margin: 1em auto;
  }

  .bottomCorner {
    text-align: right;
    float: right;
    overflow: hidden;
    margin-top: 2em;
  }

  .verticalLine {
    background-color: var(--mainIrisBlue);
    padding: .05em;
    margin: 0em -4em 0em -3em;
  }

  .logoBottom {
    display: flex;
    padding: .5em 0em;

    > p {
      font-size: 9px;
      color: var(--mainWhite);
      padding-top: 2em;
      padding-left: 1em;
    }
  }
}

.shortTermSectionVehicle {
  margin: 7em 3em 4em -1em;
  position: relative;
  background: linear-gradient(to right,  var(--mainDarkNavy) 0%, var(--mainDarkNavy) 60%, var(--mainSolitude) 40%, var(--mainSolitude) 100%);

  .shortTermContentRectangle {
    position: absolute;
    top: -5%;
    bottom: 0;
    left: 55%;
    width: 30%;
    max-height: 60%;
    background-color: var(--mainIrisBlue);
  }

  .shortTermImg {
    margin: 0 auto;
    width: 100%;
    display: block;
    padding: 1em 1.6em;
  }

  .benefits {
    color: var(--mainWhite);
    padding-left: 2.2em;
    padding-right: 2em;
    font-size: 14px;
    line-height: 2;
  }

  .shortTermTitle {
    color: var(--mainMint);
    padding-bottom: .5em;
  }

  .shortTermText {
    color: var(--mainMint);
    font-size: 14px;
  }

  .shortTermSmallText {
    font-size: 9px;
    color: var(--mainWhite);
  }

  .shortTermRight {
    margin: 3em auto 1.5em;
    padding: 1em;
  }

  .shortInsBtn {
    background-color: var(--mainMint) !important;
    color: var(--mainDarkNavy) !important;
    height: 2.5em;
    margin: 1em auto;
  }

  .bottomCorner {
    text-align: right;
    float: right;
    overflow: hidden;
  }

  .verticalLine {
    background-color: var(--mainIrisBlue);
    padding: .05em;
    margin: 0em -4em 0em -3em;
  }

  .logoBottom {
    display: flex;
    padding: .5em 0em;

    .miwayLogo {
      width: 24%;
      height: 24%;
      padding-left: 0em;
    }

    > p {
      font-size: 9px;
      color: var(--mainWhite);
      padding-top: 2em;
      padding-left: 1em;
    }
  }

  .shortTermCarRight {
    margin: 3em 1em 1.5em 2em;
    padding: 1em 2em;

    .shortTermTitle {
      color: var(--mainDarkNavy);
      padding-bottom: .5em;
    }

    .benefits {
      color: var(--mainBlack);
      padding-left: 2.2em;
      padding-right: 2em;
      font-size: 14px;
      line-height: 2.5;
    }
  }
}

@media (max-width: 767px) {
  .shortTermContentSection {
    .shortTermContentRectangle {
      display: none;
    }

    .benefits {
      padding: .5em 2.5em;
    }

    .logoBottom {
      display: block;
    }
  }

  .shortTermSectionVehicle {
    background: linear-gradient(to top,  var(--mainDarkNavy) 100%, var(--mainDarkNavy) 0%, var(--mainSolitude) 100%, var(--mainSolitude) 100%);

    .shortTermContentRectangle {
      display: none;
    }

    .shortTermCarRight {
        background-color: var(--mainSolitude);

      .benefits {
        padding: .5em .5em;
      }
    }
  }
}
