.btnLabelSection {
  text-align: center;
  margin-top: 1.5em;

  .button {
    .first {
      display: initial;
    }
    .second {
      display: none;
    }

    &:hover {
      .first {
        display: none;
      }
      .second {
        display: initial;
      }
    }
  }
}

.shrtTermBtnStyle {
  text-align: left !important;
  padding: 1em 2em 0.7em !important;
  border-radius: 0px !important;
  margin: 1em;
  height: 4em !important;
  width: 13em !important;
  font-weight: lighter !important;

  .btnHome,
  .btnVehicle,
  .btnPet,
  .btnMedical,
  .btnLegal,
  .btnBusiness {
    height: 100%;
    padding-bottom: 0.3em;
  }

  .btnHome {
    width: 11%;
  }

  .btnVehicle {
    width: 22%;
  }

  .btnPet {
    width: 16%;
  }

  .btnMedical {
    width: 13%;
  }

  .btnLegal {
    width: 12%;
  }

  .btnBusiness {
    width: 14%;
  }
}

.notDynamicClassPurple {
  background-color: var(--mainSolitude) !important;
  color: var(--mainDarkNavy) !important;
}

.dynamicClassPearl {
  color: var(--mainWhite) !important;
  background-color: var(--mainDarkNavy) !important;
  .first {
    display: none;
  }
  .second {
    display: initial;
  }
}

.shrtTermBtnStyle:hover {
  background-color: var(--mainDarkNavy) !important;
  color: var(--mainWhite) !important;
}
