.button {
  border-radius: 25px !important;
  padding: .3em 3em !important;
  border: 0px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  font-family: 'Archivo', sans-serif;
  outline: 0 !important;
  outline: none !important;
}

.button:focus {
  outline: 0 !important;
  outline: none !important;
}
