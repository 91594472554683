.wrapper {
    // padding-top: 50px;
    // padding-bottom: 10px;

    // > h2 {
    //     font-size: 7vmin;
    //     color: var(--mainDarkNavy);
    //     text-transform: uppercase;
    // }

    .contentsText {
      letter-spacing: .6px;

      > h2 {
          font-size: 2.3em;
          font-weight: normal;
          max-width: 50%;
      }

      > .firstText {
          color: var(--mainDarkNavy);
      }

      > .secondText {
          color: var(--mainDarkNavy);
          font-family: 'Lobster', cursive;
          font-size: 3em;
      }

      > .thirdText {
          color: var(--mainDarkNavy);
      }
    }

    &.sectionImageBackground {
      background-size: cover;
      background-repeat: no-repeat;
      height: 69vh;
      width: 150em;
    }
}

@media(max-width: 620px) {
    .wrapper {
        h3 {
            font-size: 3rem;
        }

        .contentsText {
          margin: 2em -3em;

          > h2 {
            max-width: 50%;
            font-size: 1.3rem !important;
          }
        }

        &.sectionImageBackground {
          height: 27vh;
        }
    }
}
