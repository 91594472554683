.chooseInsuranceSection {
  margin: 2em auto;
}

.chooseInsuranceHeader {
  color: var(--mainDarkNavy);
  text-align: center;
  font-weight: lighter;
  padding: 0em 1em;
}
