/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: var(--mainWhite);
  color: var(--mainDarkNavy);
  cursor: pointer;
  padding: 11px 5px 0px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  outline:0;
  font-size: 14px;
}

.accordion__button:focus {
  outline:0;
  outline: none;
}

.accordion__button:hover {
  background-color: var(--mainWhite);
}

.accordion__button:after {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  text-align: right;
  float: right;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  transform: rotate(223deg);
}

.accordion__panel {
  padding: 29px 5px 0px;
  font-size: 14px;
  animation: fadein 0.35s ease-in;
}

.faqAnchor {
    color: var(--mainBlack) !important;
    text-decoration: none !important;
}

.faqAnchor:hover {
  color: var(--mainBlack) !important;
  text-decoration: none !important;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
