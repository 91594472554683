.selectSection {
  input {
    border-top-color: 1px solid var(--mainGreen) !important;
    border-right-style: hidden !important;
    border-left-style: hidden !important;
    border-bottom-color: 1px solid var(--mainGreen) !important;
    border-radius: 0px !important;
    background-color: var(--mainBlack) !important;
    color: var(--mainWhite) !important;
    text-align: center !important;
    height: calc(1.9em + 0.75rem + 2px) !important;
    padding: 0.375rem 2rem 0.375rem 1.5rem !important;
    font-size: 1.5rem !important;
    border-top-style: solid !important;
    border-bottom-style: solid !important;
  }

  .form-control {
    color: var(--mainWhite) !important;
    height: calc(1.5em + 0.75rem + 11px) !important;
    margin-bottom: 2em;
  }

  b {
    width: 1.26ex !important;
    height: 1em;
    box-shadow: none !important;
    background-color: var(--mainBlack) !important;
    padding: 1em 1.5em 0.5em 0.5em;
    // top: 20% !important;
    // margin: -2em 0em !important;

    i {
      position: absolute;
      border-color: var(--mainWhite) transparent !important;
      margin: 0em 0px 0px -0.56ex;
    }
  }
}

@media screen and (max-width: 1024px) {
  .selectSection {
    b {
      padding: 1em 3em 0.5em 0.5em;
    }

    i {
      border-color: var(--mainWhite) !important;
      background-color: var(--mainWhite) !important;
    }
  }
}
