.featGsIcon {
  background-color: var(--mainGetsureGreen);
  height: 80%;
  width: 100%;

  .nsIcon {
    padding: 0em;
    margin: 0 auto !important;
    width: 100%;
    display: block;
  }
}

.policyBox {
  background-color: var(--mainSnow);
  height: 80%;
  width: 100%;
  text-align: center;
  padding: 1.5em 1em 2em;
  margin-bottom: 1em;

  .waitingIcon, .documentIcon, .nonIcon, .coolingIcon, .writtenIcon {
    // padding: 2em;
    width: 30%;
    height: 30%;
  }

  .policyText {
    margin-top: 1em;
    font-size: 13.5px;
    margin-bottom: 0em;
  }

  .policySmallText {
    font-size: 7px;
  }
}

@media(max-width: 768px) {
  .featuresRow {
    display: block !important;
    padding: 0em 1em;

    .featGsIcon {
      width: 100%;

      .nsIcon {
        width: 100%;
      }
    }

    .waitingIcon, .documentIcon, .nonIcon, .coolingIcon, .writtenIcon {
      height: 20% !important;
    }
  }
}
