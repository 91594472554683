.coverQSection {
  background-color: var(--mainGhostWhite);
  padding: 0em 1em 0em 6.5em;
  position: relative;

  .boxesRow {
    display: flex;
    position: absolute;
    top: -10%;
    bottom: 0;
    width: 85%;
    max-width: 85%;
    max-height: 55%;

    .col > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5.5em 1em 1em 1em;
    }
  }

  .boxTransition {
    height: 14em !important;
    margin-top: -1em !important;
    box-shadow: 5px 6px 3px #00000029;

    h6 {
      font-size: 18px !important;
    }
  }

  .coverSpacing {
    margin-right: 3em;
    height: 80%;
  }

  .pearlBox, .purpleBox, .yellowBox, .greenBox {
    max-height: 100%;
    max-width: 100%;
    width: 90%;
    height: 12em;
    margin: 0em 5em 2em 0em;
    font-size: 15px;
    padding: 5.5em 1em 1em 2em;
    cursor: pointer;

    h6 {
      text-align: left;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .pearlBox {
    background-color: var(--mainMint);
    color: var(--mainDarkNavy);

    .askPlanCover {
      width: 2em;
      // height: auto;
      // padding: 3.5em 0em 1em 0em;
    }

    .boxText {
      margin-top: 1em;
    }
  }

  .purpleBox {
    background-color: var(--mainMint);
    color: var(--mainDarkNavy);

    .planCoverQ {
      width: 2em;
      // height: auto;
      // padding: 3.5em 0em 1em 0em;
    }

    .boxText {
      margin-top: 1em;
    }
  }

  .greenBox {
    background-color: var(--mainMint);
    color: var(--mainDarkNavy);

    .howItWorks {
      width: 2em;
      // height: auto;
      // padding: 3.5em 0em 1em 0em;
    }

    .boxText {
      margin-top: 1em;
    }
  }

  .yellowBox {
    background-color: var(--mainMint);
    color: var(--mainDarkNavy);

    .neededCover {
      width: 2em;
      // height: auto;
      // padding: 3.5em 0em 1em 0em;
    }

    .boxText {
      margin-top: 1em;
      max-width: 14em;
    }
  }

  .coverQHeaders {
    padding: 2em 0em;
    color: var(--mainDarkGreen);
    font-weight: 400 !important;
    margin-top: 9em;

    h6 {
      font-weight: 400;
      text-align: left;
      margin: 1.5em auto;
    }
  }
}

@media (max-width: 700px) {
  .coverQSection {
    padding: 2em !important;

    .boxesRow {
      display: block !important;
      top: 0% !important;
      width: 100% !important;
      height: auto !important;
      position: relative !important;

      .row {
        display: block !important;
      }
    }

    .coverSpacing {
      margin: 0em 0em 1em 0em;
    }

    .coverQHeaders {
      margin-top: 1em !important;

      h6 {
        font-size: 14px;
        margin: .5em 0em 0em;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 701px) {
  .coverQSection {
    margin: 0em 1em;

    .boxesRow {
      left: 2%;
    }
  }
}

@media (max-width: 768px) and (min-width: 767px) {
  .coverQSection {
    .pearlBox, .purpleBox, .yellowBox, .greenBox {
      margin-right: 2em;
    }
  }
}

@media (max-width: 350px) {
  .coverQSection {
    .pearlBox, .purpleBox, .yellowBox, .greenBox {
      padding: 4.5em 1em 1em 2em;
    }

    .coverQHeaders {
      padding: 1em 0em;
      margin-top: 0em !important;
    }
  }
}
