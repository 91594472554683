.coverHeader {
  font-size: 0.8rem;
  margin: 2.5em 0em 1.5em;
  color: var(--mainWhite);
}

.coverHeaderTwo {
  font-size: 0.8rem;
  margin: 1.8em 0em 1.5em;
  color: var(--mainWhite);
}

.coverHeaderThree {
  font-size: 0.8rem;
  margin: 2.5em 0em 1.5em;
  color: var(--mainWhite);
}

.letsGoBlock {
  background-color: var(--mainIrisBlue);
  position: absolute;
  top: 48%;
  margin: 3em 0em 3em;
  padding: 1em 5.5em 0.5em;
  max-width: 91%;
  width: 91%;

  .quotePremium {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }

  .quoteButton {
    background-color: var(--mainMint) !important;
    color: var(--mainDarkNavy) !important;
    padding: 0.5em 2em !important;
    margin-bottom: 1.2em;
    box-shadow: 2px 2px 2px 1px #00000029;
    cursor: pointer;
  }

  .alignButton {
    text-align: center;
  }
}

.funeralLetsGoBlock {
  background-color: var(--mainDarkNavy);
  position: absolute;
  top: 48%;
  margin: 3em 0em 3em;
  padding: 1em 2em 0.5em;
  max-width: 91%;
  width: 91%;

  .quotePremium {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }

  .quoteButton {
    background-color: var(--mainMint) !important;
    color: var(--mainDarkNavy) !important;
    padding: 0.5em 2em !important;
    margin-bottom: 1.2em;
    margin-right: 1em;
    box-shadow: 2px 1px 2px 1px #00000029;
  }

  .alignButton {
    text-align: center;
  }

  .doubleBtns {
    display: flex;
    justify-content: center;
  }
}

.quoteFormStyle {
  input {
    border-top-color: 1px solid var(--mainSolitude) !important;
    border-right-style: hidden !important;
    border-left-style: hidden !important;
    border-bottom-color: 1px solid var(--mainSolitude) !important;
    border-radius: 0px !important;
    background-color: var(--mainDarkNavy) !important;
    color: var(--mainWhite) !important;
    text-align: left !important;
    height: calc(1.3em + 0.75rem + 2px) !important;
    padding: 0.375rem 2rem 1rem 0rem !important;
    border-top-style: hidden !important;
    border-bottom-style: solid !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  // input[type=checkbox] {
  //   appearance: none;
  // }
  //
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--mainDarkNavy) inset !important;
    -webkit-text-fill-color: var(--mainWhite) !important;
  }

  .checkbox {
    width: 100%;
    margin: 10px auto;
    position: relative;
    display: block;
  }

  .checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    // margin-left: -20px;
  }

  .checkbox label {
    position: relative;
    color: var(--mainIrisBlue);
  }

  .checkbox label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 15px;
    height: 15px;
    transition: transform 0.28s ease;
    border-radius: 3px;
    border: 1px solid var(--mainWhite);
  }

  .checkbox label:after {
    content: "";
    // display: block;
    width: 9px;
    height: 5px;
    border-bottom: 2px solid var(--mainSolitude);
    border-left: 2px solid var(--mainSolitude);
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 8px;
    left: 7px;
  }

  .checkbox input[type="checkbox"]:checked ~ label::before {
    color: var(--mainWhite);
  }

  .checkbox input[type="checkbox"]:checked ~ label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }

  .checkbox label {
    min-height: 34px;
    // display: block;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px;
    vertical-align: sub;
  }

  .checkbox label span {
    // position: absolute;
    top: 32%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
  }
}

.form-control:disabled {
  border-bottom-color: 1px solid var(--mainSilver) !important;
  color: var(--mainSilver) !important;
}

.form-group {
  margin-bottom: 1rem !important;
  padding-left: 0em !important;
}

.addAnother {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  color: var(--mainDarkNavy) !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  margin-bottom: 1em;
  text-align: center;
}

.buttonCenter {
  text-align: center !important;
}

.formCheckBox {
  margin-bottom: 2em;

  label {
    color: var(--mainSelectiveYellow);
    font-size: 12px;
    font-weight: bold;
  }

  .form-check-input {
    margin-top: -0.1rem !important;
  }
}

.titleHide {
  display: none;
}

.parentOnlyAvailable {
  margin-top: 0.2em;
}

.onlyAvailable {
  font-size: 11px;
}

@media (max-width: 1300px) {
  .letsGoBlock {
    display: contents;
    margin-bottom: 1em;

    .quotePremium {
      color: var(--mainWhite);
    }
  }

  .funeralLetsGoBlock {
    display: contents;
    margin-bottom: 1em;

    .quotePremium {
      color: var(--mainWhite);
    }
  }
}

@media (max-width: 766px) {
  .titleShow {
    display: none;
  }
}

@media (max-width: 766px) {
  .titleHide {
    display: block !important;
  }
}
