.getSureDeets {
  text-align: right;
  margin-top: 1em;
  padding-right: 2em;
  font-size: 12px;
  float: right;
}

.getSureInfo {
  color: var(--mainWhite);
}

.getSureInfo:hover {
  text-decoration: none;
  color: var(--mainSelectiveYellow);
}

.headerContainer {
  margin: .4em 0em 0em 0em;
  // display: flex;
  // justify-content: space-between;
}

.headerSection {
  position: relative;
  z-index: 100;
  background-color: var(--mainDarkNavy);

  span {
    a:hover {
      color: var(--mainWhite);
    }
  }
}
