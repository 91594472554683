.willBlockSection {
  margin: 2em auto;
  overflow: hidden;
}

.willBlockLeft {
  background-color: var(--mainDarkNavy);
  padding: 2em 2em 100% 3em;
  margin: 20px auto;
  margin-bottom: -100%;

  .willBlockHeader {
    color: var(--mainMint);
  }

  .willBlockText {
    color: var(--mainSolitude);
    font-size: 15px;
    padding-top: .5em;
  }

  .willBlockList {
    color: var(--mainSolitude);
    padding-left: 1em;
    font-size: 13px;
    line-height: 1.8;
    margin-bottom: 2em;
  }
}

.willBlockRight {
  background-color: var(--mainDarkNavy);
  padding: 2em 2em 100% 3em;
  margin: 20px auto;
  margin-bottom: -100%;

  .willBlockHeader {
    color: var(--mainMint);
  }

  .willBlockText {
    color: var(--mainSolitude);
    font-size: 15px;
    padding-top: .5em;
  }
}

@media (max-width: 500px) {
  .willRow {
    display: block !important;
  }

  .willBlockLeft {
    padding: 2em 2em 2em 3em;
    margin-bottom: 1em;
  }

  .willBlockRight {
    padding: 2em 2em 4em 3em;
    margin-bottom: 1em;
  }
}
